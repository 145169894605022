import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const NavBankAccount = props => {
  const [activeClass, setActiveClass] = useState();
  const applyActiveClass = id => {
    setActiveClass(id);
  };
  useEffect(() => {
    setTimeout(() => {
      setActiveClass(window.location.pathname.replace("/", ""));
    }, 200);
  }, []);
  
  const closeLeftMenu = () => {
    if (document.getElementById("boMenu").classList.contains("bo-menu-overlay")) {
      document.getElementById("boMenu").classList.remove("bo-menu-overlay");
      document.getElementById("body").style = "overflow: visible";
      document.getElementById("leftNav").style.cssText =
        "position: unset; width: 0; height: 0; z-index: ; display : none";
    }
  }
  return (
    <div className="col-lg-3 col-md-0 bo-sidebar-col" id="leftNav">
      <div className="bo-sidebar-outer">
        <Link
          to="/dashboardbank"
          className={
            activeClass === "dashboardbank" || activeClass === "" ? "active" : ""
          }
          onClick={() => { applyActiveClass("dashboardbank"); closeLeftMenu(); }}
        >
          <div className="logo d-flex align-items-center bo-thumb">
            {props.logo != "" ? (
              <img width="100" src={props.logo} className="img-fluid" alt="BO Payments" />
            ) : (
              <Link to="#">
                <h3>
                  Bank<span>&nbsp; Account</span>
                </h3>
              </Link>
            )}
          </div>
        </Link>
        <ul className="sidebar-ul">
          {( props?.iskycsubmitted ) ? (
            <Fragment>
              {props.role.includes("Card holder") && (
                <Fragment>
                  <li>
              <Link
                to={"/identityVerificationBankAccount"}
                id="helpNavLink"
                className={activeClass === "identityVerificationBankAccount" ? "active" : ""}
                onClick={() => { applyActiveClass("identityVerificationBankAccount"); closeLeftMenu(); }}
              >
                <i className="icon-client"></i>Kyc status
              </Link>
            </li>
              

                </Fragment>
              )}
            </Fragment>
          ) : (!props?.iskycsubmitted)? (
            <li>
              <Link
                to={"/identityVerificationBankAccount"}
                id="helpNavLink"
                className={activeClass === "identityVerificationBankAccount" ? "active" : ""}
                onClick={() => { applyActiveClass("identityVerificationBankAccount"); closeLeftMenu(); }}
              >
                <i className="icon-client"></i>Submit Kyc
              </Link>
            </li>
          ) :(
            <Fragment>
            {props.role.includes("Card holder") && (
              <Fragment>
                <li>
                  <Link
                    id="dashboardNavLink"
                    to={"/dashboardbank"}
                    className={activeClass === "dashboardbank" ? "active" : ""}
                    onClick={() => { applyActiveClass("dashboardbank"); closeLeftMenu(); }}
                  >
                    <i className="icon-dashboard"></i>Dashboard
                  </Link>
                </li>
                  <li>
                    <Link
                      id="transactionNavLink"
                      to={"/cardholdertransactionsss"}
                      className={
                        activeClass === "cardholdertransactions" ? "active" : ""
                      }
                      onClick={() =>{ applyActiveClass("cardholdertransactions");closeLeftMenu();}}
                    >
                      <i className="icon-transaction"></i>Transactions
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="settingsNavLink"
                      to={"/cardHolder/settings"}
                      className={
                        activeClass === "cardHolder/settings" ? "active" : ""
                      }
                      onClick={() => {applyActiveClass("cardHolder/settings");closeLeftMenu();}}
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/help"}
                      id="helpNavLink"
                      className={activeClass === "help" ? "active" : ""}
                      onClick={() => {applyActiveClass("help");closeLeftMenu();}}
                    >
                      <i className="icon-help"></i>Help
                    </Link>
                  </li>
                
                </Fragment>
              )}
            </Fragment>
          )}
          <hr />
            <li>
              <Link
                to={"/login"}
                className="d-flex align-items-center logout"
                onClick={() => { props.logOut(); closeLeftMenu(); }}
              >
                <i className="icon-logout"></i>
                <span>
                  <span>Logout</span>
                  <br />
                  <span className="user-name">{props.username}</span>
                </span>
              </Link>
            </li>
         
        </ul>
      </div>
    </div>
  );
};
export default NavBankAccount;
