import { Fragment, useEffect, useState } from "react";
import { Env } from "../../enviroment/environment";
import useWindowDimensions from "../../helpers/WindowWidth";
import {useSelector } from "react-redux";
import cardHolderService from "../../services/cardholder.service";
import RefreshToken from "../../helpers/RefreshToken";

const BADashBoard = (props) => {
  const { height, width } = useWindowDimensions();
  const { user: currentUser } = useSelector(state => state.auth);
  var setpermission = localStorage.getItem("activeInactiveSendmoney");
  const [cardHolderDetail, setCardHolderDetail] = useState();
  const [kycstatus, setKycstatus] = useState("");

  useEffect(() => {
    callHolderServiceCall();
  }, [props?.location?.state]);
  const callHolderServiceCall = async () => {
    await cardHolderService
      .GetAccountDetails(currentUser?.user?.UserId)
      .then(res => {
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setCardHolderDetail(res.data.payload?.item1);

          } 
        }
      })
      .catch(err => {
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
          } 
        })();
      });
  };
useEffect(()=>{
    cardHolderService.checkstatus(currentUser?.user?.UserId).then((resp)=>{
  
    let msg = JSON.parse(resp?.data?.message);
    setKycstatus(msg?.process_status);
  
}).catch((error)=>{
  try {
    let msgBody = error?.message;
    setKycstatus(msgBody);
  } catch (e) {
    console.error("Error parsing error message:", e);
  }
});
},[])
  return (
    <Fragment>
      <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
        <h3>
          Your Bank Account<span className="bo-badge bo-badge-large"></span>
        </h3>
        <div className="row mt-20">
        <div className="row-card-holder-details mb-40">
            <div className="row flex-column-reverse flex-sm-row">
              <div className="col-md-12 col-sm-8">
                <ul className="ul-card-details">
                  <li>
                    <div className="mb-20">
                      <p>Client Name</p>
                      <h5>{cardHolderDetail?.clientName}</h5>
                    </div>

                    <div className="mb-20">
                      <p>Email</p>
                      <h5 className="text-break"> {cardHolderDetail?.email}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Currency</p>
                      <h5>
                        $
                      </h5>
                    </div>
                    <div className="mb-20">
                      <p>Date of Birth</p>
                      <h5>
                      <h5> {cardHolderDetail?.firstName+ ` `+cardHolderDetail?.lastName}</h5>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="mb-20">
                      <p>Account Title</p>
                      <h5> {cardHolderDetail?.firstName+ ` `+cardHolderDetail?.lastName}</h5>
                    </div>
                    <div className="mb-20">
                      <p>City</p>
                      <h5> {cardHolderDetail?.city}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Country</p>
                      <h5> {cardHolderDetail?.country}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Zip Code</p>
                      <h5> {cardHolderDetail?.zipCode}</h5>
                    </div>
                  </li>
                  <li>
                  <div className="mb-20">
                      <p>Account Number ACH</p>
                      <h5> {cardHolderDetail?.accountNumberAch}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Account Number FED</p>
                      <h5> {cardHolderDetail?.accountNumberFed}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Routing Number ACH</p>
                      <h5> {cardHolderDetail?.routingNumberAch}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Routing Nunber FED</p>
                      <h5> {cardHolderDetail?.routingNumberFed}</h5>
                    </div>
                  </li>
                 <li>
                 <div className="mb-20">
                      <p>Token Id</p>
                      <h5> {cardHolderDetail?.tokenId}</h5>
                    </div>
                    <div className="mb-20">
                      <p>KYC Status</p>
                      <h5> {kycstatus}</h5>
                    </div>
                 </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* for mobile */}
        <div className="row">
       
        
        </div>

       
      </div>
   

      
    </Fragment>
  );
};

export default BADashBoard;
