import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.UserApiUrl; //"http://localhost:8000/api/users/";
const API_URL_Upload = Env.UploadDocuments; //"http://localhost:8000/api/Upload/";
const API_URL_Cardholders = Env.CardHolderApiUrl; //"http://localhost:8000/api/Upload/";
const API_URL_BANKACCOUNT = Env.BankAccountApiUrl; //"http://localhost:8000/api/Upload/";

const identityverification = (IssuingCountry, IDType, AddressProof, FilePathIdType, FilePathAddressProof,
  UserId) => {
  return axios
    .post(API_URL + "identityVerification", {
      IssuingCountry, IDType, AddressProof, FilePathIdType, FilePathAddressProof,
      UserId
    }, {
      headers: authHeader(),
    }
    )
    .then((response) => {
      return response;
    });
};
const updateCardHolderIdentityDocuments = (IssuingCountry, IDType, AddressProof, FilePathIdType, FilePathAddressProof, UserId,
  ClientId) => {
  return axios
    .post(API_URL + "updateuseridentities", {
      IssuingCountry,
      IDType, AddressProof,
      FilePathIdType,
      FilePathAddressProof,
      UserId,
      ClientId
    },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getIdTypeEnums = () => {
  return axios
    .get(API_URL + "getAllIdTypes", {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const getProofofAddressEnums = () => {
  return axios
    .get(API_URL + "getAllIdentityProof", {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const uploaduserdocs = (formData, options) => {
  return axios
    .post(API_URL_Upload + "uploaduserdocs",
      formData, options, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const getuseridentities = (userId) => {
  return axios
    .get(API_URL + "getuseridentities?userId=" + userId, { headers: authHeader() })
    .then((res) => {
      return res;
    });
};
//986b2e1f-886a-4e3b-a0b4-2d27d72eb3e4 getadmindetail
const IssuerGKyc = (IdNumber,
  IdType,
  IdDocLang,
  AddressType,
  AddressDocLang,
  //ID Type
  IdFrontPic,
  IdBackPic,
  //Address Front Back
  AddressFrontPic,
  AddressBackPic,
  UserId,
  IdFrontPicBase64,
  IdBackPicBase64,
  AddressFrontPicBase64,
  AddressBackPicBase64,
  StateId,
  idIssuedBy,
  idIssueDate,
  idExpDate,
  AddressIssuedBy,
  AddressIssueDate
) => {
  return axios
    .post(API_URL + "issuergkyc", {
      IdNumber,
      IdType,
      IdDocLang,
      AddressType,
      AddressDocLang,
      //ID Type
      IdFrontPic,
      IdBackPic,
      //Address Front Back
      
      AddressFrontPic,
      AddressBackPic,
      UserId,
      IdFrontPicBase64,
      IdBackPicBase64,
      AddressFrontPicBase64,
      AddressBackPicBase64,
      StateId,
      idIssuedBy,
      idIssueDate,
      idExpDate,
      AddressIssuedBy,
      AddressIssueDate
    }, {
      headers: authHeader(),
    }
    )
    .then((response) => {
      return response;
    });
};
const issuertkyc = (
  userId,
  DocType,
  Language,
  DocNumber,
  IssuedBy,
  IssueDate,
  ExpireDate,
  ImageFront,
  ImageFrontExt,
  ImageBack,
  ImageBackExt,
  ImageFrontUrl,
  ImageBackUrl
) => {
  return axios
    .post(API_URL + "issuertkyc", {
      userId,
      DocType,
      Language,
      DocNumber,
      IssuedBy,
      IssueDate,
      ExpireDate,
      ImageFront,
      ImageFrontExt,
      ImageBack,
      ImageBackExt,
      ImageFrontUrl,
      ImageBackUrl,
    }, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const deleteKyct = (userId, kycId) => {
  return axios
    .post(
      API_URL + "deleteKyct?userId=" + userId + "&kycId=" + kycId,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const viewcardholdert = (userId) => {
  return axios
    .get(API_URL_Cardholders + "viewcardholdert?userId="+userId, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const submitkyctoissuert = (userId) => {
  return axios
    .post(
      API_URL + "submitkyctoissuert?userId=" + userId,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const uploaddocument = (Application_Id, Document_type, Document_front, Document_back, UserId,
  clientId) => {
  return axios
    .post(API_URL_BANKACCOUNT + "uploaddocument", {Application_Id, Document_type, Document_front, Document_back, UserId,
      clientId
    }, {
      headers: authHeader(),
    }
    )
    .then((response) => {
      return response;
    });
};
const uploadagreement = (Application_Id, Layer2_agreement, Proof_address_type, Account_agreement, Proof_address_doc,
  Accept_other_terms,UserId,clientId) => {
  return axios
    .post(API_URL_BANKACCOUNT + "uploadagreement", {Application_Id, Layer2_agreement, Proof_address_type, Account_agreement, Proof_address_doc,
      Accept_other_terms,UserId,clientId
    }, {
      headers: authHeader(),
    }
    )
    .then((response) => {
      return response;
    });
};


const identityverificationObj = {
  identityverification,
  uploaduserdocs,
  getIdTypeEnums,
  getProofofAddressEnums,
  getuseridentities,
  updateCardHolderIdentityDocuments,
  IssuerGKyc,
  issuertkyc,
  deleteKyct,
  viewcardholdert,
  submitkyctoissuert,
  uploaddocument,
  uploadagreement
};


export default identityverificationObj;
