import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import identityverificationObj from "../../services/identityverification.service";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import { Fragment } from "react";
import { history } from "../../helpers/history";
import BoLoader from "../../helpers/BoLoader";
import crossimg from "../../assets/images/icons/cross.svg";
import DialoguePopup from "../../helpers/DialoguePopup";
import useWindowDimensions from "../../helpers/WindowWidth";

const IdentityVerificationBankAccount = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [AddressProof, setAddressProof] = useState();
    const { height, width } = useWindowDimensions();
    const [FilePathAddressProof, setFilePathAddressProof] = useState("");
    const [AddressProofList, setAddressProofList] = useState([]);
    const [IDTypeList, setIDTypeList] = useState([]);
    const [IDType, setIDType] = useState();
    const [FilePathIdType, setFilePathIdType] = useState("");
    const [IssuingCountry, setIssuingCountry] = useState("");
    const [successfulSubmitted, setSuccessfulSubmitted] = useState(currentUser?.user?.IsKycSubmitted ?? false);
    const [allowKYC, setAllowKYC] = useState(false);

    const [LogoNameID, setlogoNameID] = useState("");
    const [LogoNamePOF, setlogoNamePOF] = useState("");
    const [LogoSizeID, setlogoSizeID] = useState();
    const [LogoSizePOF, setlogoSizePOF] = useState();
    const [isValidIdType, setiisValidIdType] = useState(true);
    const [isValidProofofAdrs, setiisValidProofofAdrs] = useState(true);
    const [isValidLogoIdType, setiisValidLogoIdType] = useState(true);
    const [isValidLogoProofofAdrs, setisValidLogoProofofAdrs] = useState(true);
    const [UserId, setiUserId] = useState(currentUser?.user?.UserId);
    const [ClientId, setClientId] = useState(currentUser?.user?.ClientId);
    const [ApplicationId, setApplicationId] = useState(currentUser?.user?.ApplicationId);
    const [State, seState] = useState();
    const [CheckDocument, setCheckDocument] = useState(true);
    const [CheckAgreement, setCheckAgreement] = useState(false);


    const POAAddress = useRef();
    //loader
    const [boloader, setBoLoader] = useState(false);
    //Dialogue Popup
    const [modalShow, setModalShow] = useState(false);
    const [modelhdng, setMdlHdng] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");
    const [isValidFileId, setisValidFileId] = useState(true);
    const [isValidFilePoa, setiisValidFilePoa] = useState(true);

    var dropDownIcon = {
        paddingLeft: "45px",
    };
    var mainContent = "";

    useEffect(() => {
        setiisValidIdType(true);
    }, [IDType]);
    useEffect(() => {
        setiisValidProofofAdrs(true);
    }, [AddressProof]);
    useEffect(() => {
        setiisValidLogoIdType(true);
    }, [LogoNameID]);
    useEffect(() => {
        setisValidLogoProofofAdrs(true);
    }, [LogoNamePOF]);
    useEffect(() => {
        setisValidFileId(true);
    }, [setlogoSizeID]);
    useEffect(() => {
        setiisValidFilePoa(true);
    }, [setlogoSizePOF]);

    const changeHandlerPoa = (e) => {
        const status = e.target.selectedOptions[0].value;
        setAddressProof(status);
    };
    const changeHandlerIdType = (e) => {
        const status = e.target.selectedOptions[0].value;
        setIDType(status);
    };
    const checkUpdateSave = () => {
        if (currentUser?.user?.KycApprovedByIssuer === "No")
            updateUser();
        else
            saveUserAgreement();

    }
    const updateUser = () => {

        if (State === "To the Card Holder Account") {
            if (IDType === null || IDType === undefined) {
                setiisValidIdType(false);
                return;
            } else setiisValidIdType(true);
            if (LogoNameID.trim() == null || LogoNameID.trim() === "") {
                setiisValidLogoIdType(false);
                return;
            } else setiisValidLogoIdType(true);
        }
        if (AddressProof == null || AddressProof === undefined) {
            setiisValidProofofAdrs(false);
            return;
        } else setiisValidProofofAdrs(true);
        if (LogoNamePOF.trim() == null || LogoNamePOF.trim() === "") {
            setisValidLogoProofofAdrs(false);
            return;
        } else setisValidLogoProofofAdrs(true);
        if (FilePathIdType === "") {
            setiisValidLogoIdType(false);
            return;
        }
        if (FilePathAddressProof === "") {
            setisValidLogoProofofAdrs(false);
            return;
        }
        setBoLoader(true);
        identityverificationObj
            .uploaddocument(
                currentUser?.user?.ApplicationId,
                IDType,
                FilePathIdType,
                "",
                UserId
            )
            .then((response) => {
                setBoLoader(false);
                if (!response.data.isError) {
                    // alert("saved successfully");
                    setAddressProof("");
                    setIDType("");
                    setIssuingCountry("");
                    setSuccessfulSubmitted(true);
                }
            })
            .catch((err) => {
                setBoLoader(false);
                let msgBody = err.response.data.title
                    ? err.response.data.title
                    : "Internal Server Error";
                let errIcon = "icon-error";
                errorAndSuccessModl("Error", msgBody, errIcon);
            });
    };


    const saveUserDocument = () => {
        if (IDType === null || IDType === undefined) {
            setiisValidIdType(false);
            return;
        }
        if (LogoNameID.trim() == null || LogoNameID.trim() === "") {
            setiisValidLogoIdType(false);
            return;
        } else setiisValidLogoIdType(true);
        if (FilePathIdType === "") {
            setiisValidLogoIdType(false);
            return;
        }
        setBoLoader(true);
        identityverificationObj
            .uploaddocument(
                ApplicationId,
                IDType,
                FilePathIdType,
                "",
                UserId,
                ClientId
            )
            .then((response) => {
                setBoLoader(false);
                if (!response.data.isError) {
                    setIDType();
                    setCheckDocument(false);
                    setCheckAgreement(true);
                }
            })
            .catch((err) => {
                setBoLoader(false);
                let msgBody = err?.response?.data?.title
                    ? err?.response?.data?.title
                    : "Internal Server Error";
                let errIcon = "icon-error";
                errorAndSuccessModl("Error", msgBody, errIcon);
            });
    };
    const saveUserAgreement = () => {
        if (AddressProof == null || AddressProof === undefined) {
            setiisValidProofofAdrs(false);
            return;
        } else setiisValidProofofAdrs(true);
        if (LogoNamePOF.trim() == null || LogoNamePOF.trim() === "") {
            setisValidLogoProofofAdrs(false);
            return;
        } else setisValidLogoProofofAdrs(true);
        if (FilePathAddressProof === "") {
            setisValidLogoProofofAdrs(false);
            return;
        }
        setBoLoader(true);
        identityverificationObj
            .uploadagreement(
                ApplicationId,
                "",
                AddressProof,
                "",
                FilePathAddressProof,
                "",
                UserId,
                ClientId
            )
            .then((response) => {
                setBoLoader(false);
                if (!response.data.isError) {
                    setAddressProof();
                    setCheckDocument(false);
                    setCheckAgreement(false);
                    setSuccessfulSubmitted(true);
                }
            })
            .catch((err) => {
                setBoLoader(false);
                let msgBody = err?.response?.data?.title
                    ? err?.response?.data?.title
                    : "Internal Server Error";
                let errIcon = "icon-error";
                errorAndSuccessModl("Error", msgBody, errIcon);
            });
    };
    const errorAndSuccessModl = (heading, body, errIcon) => {
        setModalShow(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(errIcon);
    };

    const onChangeIDtype = (e) => {
        const file_data = e.target.files[0];
        const formdata = new FormData();
        let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
        if (!file_data.name.match(reg)) {
            setisValidFileId(false);
            return false;
        }
        else if (file_data.size > 1000000) {
            setisValidFileId(false);
            return false;
        }
        setlogoNameID(file_data.name);
        var size = formatBytes(file_data.size);
        setlogoSizeID(size);
        formdata.append("File", file_data);
        identityverificationObj.uploaduserdocs(formdata).then((response) => {
            if (response) {
                setFilePathIdType(response.data.payload);
            }
        });
    };

    const onChangeProofofaddress = (e) => {
        const file_data = e.target.files[0];
        const formdata = new FormData();
        let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
        if (!file_data.name.match(reg)) {
            setiisValidFilePoa(false);
            return false;
        }
        else if (file_data.size > 1000000) {
            setiisValidFilePoa(false);
            return false;
        }
        setlogoNamePOF(file_data.name);
        var size = formatBytes(file_data.size);
        setlogoSizePOF(size);
        formdata.append("File", file_data);
        identityverificationObj.uploaduserdocs(formdata).then((response) => {
            if (response) {
                setFilePathAddressProof(response.data.payload);
            }
        });
    };

    const onCrossImg = () => {
        setlogoNamePOF("");
        setisValidLogoProofofAdrs(true);
        setiisValidFilePoa(true);
    };

    const onCrossImgId = () => {
        setlogoNameID("");
        setiisValidLogoIdType(true);
        setisValidFileId(true);
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    return (
        <div className="col-lg-9 row minvh-100">
            {props?.calledfrom === "register" && (
                <>
                    {width > 767 && (
                        <BoPaymentWrapper
                            pagename={["Create An", <br />, "Account"]}
                            pagemsg={State}
                        />
                    )}

                </>
            )}
            <div className={mainContent}>
                <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl onbo-content-ipadpro">
                    <div className="row">
                        <BoLoader loading={boloader} />
                        {!successfulSubmitted && (
                            <div className="position-relative">
                                <div className="col-sm-12 col-xl-10">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-md-12 mb-24">
                                                {currentUser?.user?.KycApprovedByIssuer === "No" ? (<h3>Your Kyc Is rejected Please Upload Again</h3>) : (<h3>Verify Your Identity</h3>)}
                                            </div>

                                            {
                                                CheckDocument && (<Fragment>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Select ID Type*</label>
                                                            <div className="form-controls">
                                                                <select
                                                                    onChange={changeHandlerIdType}
                                                                    className="form-control no-icon"
                                                                    style={dropDownIcon}
                                                                >
                                                                    <option value="">Select...</option>
                                                                    <option value="passport">Passport</option>
                                                                    <option value="drivers_licence">Drivers Lisence</option>
                                                                </select>
                                                                <span className="icon-card-id _ico"></span>
                                                                {!isValidIdType && (
                                                                    <span className="help-block has_error">
                                                                        ID Type is Required
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {LogoNameID === "" ? (
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="email"
                                                                    className="d-xs-none d-md-inline-block"
                                                                >
                                                                    &nbsp;&nbsp;
                                                                </label>
                                                                <div className="form-controls">
                                                                    <div className="bo-file-control">
                                                                        <input
                                                                            type="file"
                                                                            hidden
                                                                            id="idtype"
                                                                            className="form-control"
                                                                            name="logo"
                                                                            onChange={onChangeIDtype}
                                                                        />
                                                                        <input
                                                                            className="form-control file-control-input"
                                                                            type="file"
                                                                            readOnly
                                                                            onChange={onChangeIDtype}
                                                                        />
                                                                        <h5 className="form-control file-control file-control-text">
                                                                            <label>Drag files here to upload or </label>{" "}
                                                                            Browse file
                                                                        </h5>
                                                                        {!isValidFileId && (
                                                                            <span className="help-block has_error">
                                                                                Invalid file format
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <span className="icon-upload-file _ico">
                                                                        <span className="path1"></span>
                                                                        <span className="path2"></span>
                                                                    </span>
                                                                </div>
                                                                <span className="fs-12 clr-submarine mt-2 d-block">
                                                                    Supported file types jpg,jpeg & pdf with
                                                                    transparent background and max size 10 mb
                                                                </span>
                                                                {!isValidLogoIdType && (
                                                                    <span className="help-block has_error">
                                                                        ID Type is Required
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="bo-file d-flex">
                                                                    <div className="file-name">
                                                                        <span className="icon-upload-file _ico">
                                                                            <span className="path1"></span>
                                                                            <span className="path2"></span>
                                                                        </span>
                                                                        {/* <img src={fileuploadimg} alt="" /> */}
                                                                        <h5>
                                                                            <span>{LogoNameID}</span>
                                                                        </h5>
                                                                    </div>
                                                                    <div
                                                                        className="ml-auto file-size"
                                                                        onClick={onCrossImgId}
                                                                    >
                                                                        <span>{LogoSizeID}</span>
                                                                        <img
                                                                            className="cross-icon"
                                                                            src={crossimg}
                                                                            onClick={onCrossImgId}
                                                                            alt=""
                                                                        />

                                                                    </div>

                                                                </div>
                                                            </div>
                                                            {!isValidLogoIdType && (
                                                                <span className="help-block has_error">
                                                                    Valid ID Type is Required
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Fragment>)
                                            }
                                            {CheckAgreement && (<Fragment>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="email">Proof of Address*</label>
                                                        <div className="form-controls">
                                                            <select
                                                                onChange={changeHandlerPoa}
                                                                className="form-control no-icon"
                                                                style={dropDownIcon}
                                                            >
                                                                <option value="">Select...</option>
                                                                <option value="BANK_STATEMENT">Bank Statement</option>
                                                                <option value="UTILITY_BILL">Utility Bill</option>
                                                                <option value="LEASE_AGREEMENT">Lease Agreement</option>

                                                            </select>
                                                            <span className="icon-address _ico"></span>
                                                            {!isValidProofofAdrs && (
                                                                <span className="help-block has_error">
                                                                    Proof of Address is Required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {LogoNamePOF === "" ? (
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="email"
                                                                className="d-xs-none d-md-inline-block"
                                                            >
                                                                &nbsp;&nbsp;
                                                            </label>
                                                            <div className="form-controls">
                                                                <div className="bo-file-control">
                                                                    <input
                                                                        type="file"
                                                                        id="proofofaddress"
                                                                        hidden
                                                                        className="form-control file-control"
                                                                        placeholder="Drag files here to upload or"
                                                                        name="logo"
                                                                        onChange={onChangeProofofaddress}
                                                                        ref={POAAddress}
                                                                    />
                                                                    <input
                                                                        className="form-control file-control-input"
                                                                        type="file"
                                                                        readOnly
                                                                        onChange={onChangeProofofaddress}
                                                                        ref={POAAddress}
                                                                    />
                                                                    <h5 className="form-control file-control file-control-text">
                                                                        <label>Drag files here to upload or </label>{" "}
                                                                        Browse file
                                                                    </h5>
                                                                    {!isValidFilePoa && (
                                                                        <span className="help-block has_error">
                                                                            Invalid file format
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <span className="icon-upload-file _ico">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                </span>
                                                            </div>
                                                            <span className="fs-12 clr-submarine mt-2 d-block">
                                                                Supported file types jpg,jpeg & pdf with
                                                                transparent background and max size 10 mb
                                                            </span>
                                                            {!isValidLogoProofofAdrs && (
                                                                <span className="help-block has_error">
                                                                    Proof of Address Document is Required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <div className="bo-file d-flex">
                                                                <div className="file-name">
                                                                    <span className="icon-upload-file _ico">
                                                                        <span className="path1"></span>
                                                                        <span className="path2"></span>
                                                                    </span>
                                                                    {/* <img src={fileuploadimg} alt="" /> */}
                                                                    <h5>
                                                                        <span>{LogoNamePOF}</span>
                                                                    </h5>
                                                                </div>
                                                                <div
                                                                    className="ml-auto file-size"
                                                                    onClick={onCrossImg}
                                                                >
                                                                    <span>{LogoSizePOF}</span>
                                                                    <img
                                                                        className="cross-icon"
                                                                        src={crossimg}
                                                                        onClick={onCrossImg}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!isValidLogoProofofAdrs && (
                                                            <span className="help-block has_error">
                                                                Valid Proof of address is required
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </Fragment>)}

                                            <div className="col-lg-6 mt-3">
                                                <div className="form-group submit-field d-flex justify-content-between">
                                                    {
                                                        CheckDocument ? (
                                                            <button className="btn btn-large" onClick={saveUserDocument}>
                                                                Submit
                                                            </button>
                                                        ) : CheckAgreement && (<button className="btn btn-large" onClick={saveUserAgreement}>
                                                            Submit
                                                        </button>)
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                        {successfulSubmitted && (
                            <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
                                <div className="col-md-8 col-lg-5">
                                    <div className="icon-alert">
                                        <span className="icon-loading">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </span>
                                    </div>
                                    <h3 className="mb-4">We are reviewing your account</h3>

                                    <h6>
                                        We are reviewing your uploaded documents. This usually take
                                        less then 24 hours.
                                    </h6>
                                    {/* <div className="form-group submit-field mt-40">
                  <button
                    className="btn btn-large w-100"
                    onClick={() => history.push("/login")}
                  >
                    OK
                  </button>
                </div> */}
                                </div>
                            </div>
                        )}
                        {
                            allowKYC && (
                                <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
                                    <div className="col-md-8 col-lg-5">
                                        <div className="icon-alert">
                                            <span className="icon-loading">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </span>
                                        </div>
                                        <h3 className="mb-4">KYC Submitted Successfully !</h3>
                                        {/* <h6>
                Click to Login screen.
                </h6> */}
                                        <div className="form-group submit-field mt-40">
                                            <button
                                                className="btn btn-large w-100"
                                                onClick={() => history.push("/login")}
                                            >
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <DialoguePopup
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modelheading={modelhdng}
                    modelbody={modelbdy}
                    modelicon={MdlIcon}
                />
            </div>
        </div>
    );
};

export default IdentityVerificationBankAccount;
