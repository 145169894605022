import { Fragment,useState } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import BADashBoard from "./BADashBoard";
const DashboardBankAccount = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  if (!currentUser) {
    dispatch(logout());
    return <Redirect to="/login" />;
  }
  if(currentUser?.user?.IsKycSubmitted==false )
    return <Redirect to="/identityVerificationBankAccount" />;
  return (
    <Fragment>
  <Fragment>
 <BADashBoard  sendmoneypermission={haspermissions?.CardToCard} 
 isphysicalcard={currentUser?.user?.IsPhysicalCard } 
 clientid={currentUser?.user?.ClientId} />
  </Fragment>
  

      

    </Fragment>
  );

};

export default DashboardBankAccount;
